import { createContext, useContext, useEffect, useState } from "react";
import { auth, db } from "../firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  onAuthStateChanged,
  updateProfile,
} from "firebase/auth";
import axios from "axios";
import requests from "../Requests";

const AuthContext = createContext();

export function AuthContextProvider({ children }) {
  const [user, setUser] = useState({});
  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": `${process.env.APP_DOMAIN}`,
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  };

  function signUp(email, password, displayName, isChecked) {
    return createUserWithEmailAndPassword(auth, email, password).then((res) => {
      const user = res.user;

      // APIのAuthenticationトリガーで先にStripeオブジェクトがviewersコレクションに作成されるのでUPDATEする
      axios
        .post(
          requests.requestUpdateViewer + user.uid,
          {
            updateData: {
              id: user.uid,
              profile: {
                name: displayName,
                uid: user.uid,
                pic: {
                  profile: "",
                  thumbnail: "",
                },
              },
              address: {
                add1: "",
                add2: "",
                city: "",
                state: "",
                zip: "",
              },
              contacts: {
                email: email,
                mobile: "",
              },
              roles: [""],
              isActive: true,
              isAgreed: isChecked,
              created_at: new Date(),
              created_by: user.uid,
              updated_at: new Date(),
              updated_by: user.uid,
            },
          },
          { headers }
        )
        .then((res) => {
          return updateProfile(res.user, displayName).then((res) => {
            console.log("res: ", res);
          });
        });
    });
  }

  function logIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logOut() {
    return signOut(auth);
  }

  function passwordRemind(email) {
    return sendPasswordResetEmail(auth, email);
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => {
      unsubscribe();
    };
  });

  return (
    <AuthContext.Provider
      value={{ signUp, logIn, logOut, passwordRemind, user }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function UserAuth() {
  return useContext(AuthContext);
}
