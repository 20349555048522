import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useContext } from "react";
// import MovieContext from "../MovieContext";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import { motion } from "framer-motion";
import poupelleImage from "../assets/poupelle.jpg";
import defaultImage from "../assets/no-image.jpg";

const Div = styled.div`
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  &:hover {
    box-shadow: 0px 0px 24px 0px hsl(223, 36%, 36%);
  }
  &.shadow {
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.75) 0%,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 0) 100%
    );
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }
`;

const H2 = styled.h2`
  margin: 15px 10px;
  font-weight: 600;
  position: absolute;
  bottom: 0;
  font-size: 1rem;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Svg = styled.svg`
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 24px;
  cursor: pointer;
  z-index: 1;
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
`;

const Content = ({ content }) => {
  // const { addToFavourites, isFav } = useContext(MovieContext);

  return (
    <Div>
      <motion.div
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        exit={{ opacity: 0 }}
        layout
      >
        {/* {isFav(movie.id) ? (
          <AiOutlineStar onClick={() => addToFavourites(movie)} />
        ) : (
          <AiFillStar onClick={() => addToFavourites(movie)} />
        )} */}
        {content.poster_path !== "" && content.length !== 0 ? (
          // <Link to={`/content/${content.id}`} key={content.id}>
          //   <motion.div layout>
          //     <Div>
          //       {/* <ContentDetail key={content.id} content={content}> */}
          //       <Img src={poupelleImage} />
          //       {/* </ContentDetail> */}
          //     </Div>
          //   </motion.div>
          // </Link>
          <>
            <motion.div layout>
              <Div>
                <Link
                  to={{
                    pathname: `/content-detail`,
                    state: content,
                  }}
                >
                  <Img src={poupelleImage} />
                </Link>
              </Div>
            </motion.div>
          </>
        ) : (
          // <Img src={"https://image.tmdb.org/t/p/w500" + content.poster_path} />
          <Img src={defaultImage} />
        )}
        <H2>{content.title}</H2>
      </motion.div>
    </Div>
  );
};

export default Content;
