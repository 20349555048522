import React, { useState, useContext } from "react";
import styled from "styled-components";
import { RiSearchLine } from "react-icons/ri";
// import MovieContext from "../MovieContext";

const Div = styled.div`
  width: 100%;
  margin-top: 15px;
  padding: 10px 0;
  border-radius: 25px;
`;

const Svg = styled.svg`
  font-size: 16px;
`;

const Input = styled.input`
  background-color: transparent;
  border: none;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  color: hsl(224, 32%, 94%);
  margin-top: 70px;
  margin-left: 15px;
  width: 80%;
  &:focus {
    outline: none;
  }
`;

const Search = () => {
  const [value, setValue] = useState("");
  // const { fetchPopular, fetchSearch } = useContext(MovieContext);

  const onKeyUp = (event) => {
    if (event.key === "Enter" && value !== "") {
      const query = value.trim();
      //   if (query === "") {
      //     fetchPopular();
      //   } else {
      //     fetchSearch(query);
      //   }
      setValue("");
    }
  };

  return (
    <>
      <Div>
        <label htmlFor="search">
          <RiSearchLine />
        </label>
        <Input
          type="text"
          id="search"
          placeholder="Search for movies"
          onKeyDown={(e) => onKeyUp(e)}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </Div>
    </>
  );
};

export default Search;