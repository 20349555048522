import React, { useEffect, useState } from "react";
export const useContent = () => {
  const [contents, setContents] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [activeGenre, setActiveGenre] = useState(0);
  const [header, setHeader] = useState("Poupelle");

  // localstorage state
  // const [favourites, setFavourites] = useLocalStorage("fav", []);

  useEffect(() => {
    const query = "Poupelle";
    // const data = await fetch(
    //   `https://api.themoviedb.org/3/search/movie?api_key=3f196e0ed22f986793388b32ebd44d90&language=ja-JP&query=${query}&page=1&include_adult=false`
    // );

    // TODO firestoreからcontentを取得
    const data = {
      page: 1,
      results: [
        {
          adult: false,
          backdrop_path: "/lhBScerHCFbzKJqeqU8Ig6AawQM.jpg",
          genre_ids: [16, 18, 14, 12],
          id: 655437,
          original_language: "ja",
          original_title: "",
          overview:
            "厚い煙に覆われた“えんとつ町”。煙の向こうに“星”があるなんて誰も想像すらしなかった。一年前、この町でただ一人、紙芝居に託して“星”を語っていたブルーノが突然消えてしまい、人々は海の怪物に食べられてしまったと噂した。ブルーノの息子・ルビッチは、学校を辞めてえんとつ掃除屋として家計を助ける。しかしその後も父の教えを守り“星”を信じ続けていたルビッチは町のみんなに嘘つきと後ろ指をさされ、ひとりぼっちになってしまう。そしてハロウィンの夜、彼の前に奇跡が起きた。ゴミから生まれたゴミ人間・プペルが現れ、のけもの同士、二人は友達となる。そんなある日、巨大なゴミの怪物が海から浮かび上がる。それは父の紙芝居に出てきた、閉ざされたこの世界には存在しないはずの“船”だった。父の話に確信を得たルビッチは、プペルと「星を見つけに行こう」と決意する。しかしこの町の治安を守る異端審問官が二人の計画を阻止するために立ちはだかる。それでも父を信じて、互いを信じあって飛び出した二人が、大冒険の先に見た、えんとつ町に隠された驚きの秘密とは？",
          popularity: 8.255,
          poster_path: "/z0W6hrjGd9NrY4eQypaNDGlLkN9.jpg",
          release_date: "2020-12-25",
          title: "",
          video: false,
          vote_average: 7,
          vote_count: 15,
        },
        {
          adult: false,
          backdrop_path: "",
          genre_ids: [0, 0, 0, 0],
          id: 0,
          original_language: "",
          original_title: "",
          overview: "",
          popularity: 0,
          poster_path: "",
          release_date: "",
          title: "",
          video: false,
          vote_average: 0,
          vote_count: 0,
        },
        {
          adult: false,
          backdrop_path: "",
          genre_ids: [0, 0, 0, 0],
          id: 0,
          original_language: "",
          original_title: "",
          overview: "",
          popularity: 0,
          poster_path: "",
          release_date: "",
          title: "",
          video: false,
          vote_average: 0,
          vote_count: 0,
        },
        {
          adult: false,
          backdrop_path: "",
          genre_ids: [0, 0, 0, 0],
          id: 0,
          original_language: "",
          original_title: "",
          overview: "",
          popularity: 0,
          poster_path: "",
          release_date: "",
          title: "",
          video: false,
          vote_average: 0,
          vote_count: 0,
        },
      ],
      total_pages: 1,
      total_results: 1,
    };
    setContents(data.results);
    setFiltered(data.results);
    setHeader(`Results for "${query}"`);
    setActiveGenre(0);
  }, []);
  return {
    contents,
    setContents,
    filtered,
    setFiltered,
    activeGenre,
    setActiveGenre,
    header,
    setHeader,
  };
};

// import { useLocalStorage } from "../useLocalStorage";

// interface IContent {
//   videos: {
//     id: string;
//     title: string;
//     description: string;
//     audience: string;
//     genre_id: string;
//     provider: string;
//     url: string;
//     search_keys: string;
//     price: any;
//     streaming_start_at: string;
//     release_date: string;
//     cast: string;
//     direction: string;
//     countries: string;
//     languages: string;
//     approval_request: string;
//     status: boolean;
//     created_at: string;
//     created_by: string;
//   }
// }

// export const useContent = () => {
//   //   const [videos, setVideos] = useState([] as IContent[]);
//   const [videos, setVideos] = useState([]);
//   const [activeGenre, setActiveGenre] = useState(0);
//   const [header, setHeader] = useState("The BeatsBase");

//   // localstorage state
//   // const [favourites, setFavourites] = useLocalStorage("fav", []);
//   const provider = "1001"; //This is for temp purpose. Please change it with the provider id later.
//   // useEffect(() => {
//   //   const query = "Poupelle";

//   //   // fetchVideos()
//   //   //   .then((results) => {
//   //   //   console.log(results);
//   //   //   const data = {
//   //   //     page: 1,
//   //   //     results: results,
//   //   //     total_pages: 1,
//   //   //     total_results: 1
//   //   //   };

//   //   //   setHeader(`Results for "${query}"`);
//   //   //   setActiveGenre(0);
//   //   // });
//   // }, []);
//   const fetchVideos = async() => {
//     const resVideos = await fetch(`https://us-central1-dev-thebeatsbase.cloudfunctions.net/videosApi/getAll/${provider}`);
//     console.log('resVideos: ', resVideos);
//     const res: Array<IContent> = await resVideos.json();
//     console.log('res: ', res);
//     // console.log("list of videos", res);
//     setVideos(res);

//     // console.log(res);
//     const data = {
//       page: 1,
//       results: res,
//       total_pages: 1,
//       total_results: 1
//     };

//     setHeader(`Results for "${res}"`);
//     setActiveGenre(0);
//     return res;
//   };
//   fetchVideos();
//   return {
//     videos,
//     setVideos,
//     activeGenre,
//     setActiveGenre,
//     header,
//     setHeader,
//     fetchVideos
//   };
// };
