const prefix = process.env.PROJECT_ENV;

const requests = {
  requestPopular: `https://us-central1-${prefix}-thebeatsbase.cloudfunctions.net/videosApi/getAll/1001`,
  requestTopRated: `https://us-central1-${prefix}-thebeatsbase.cloudfunctions.net/videosApi/getAll/1001`,
  requestTrending: `https://us-central1-${prefix}-thebeatsbase.cloudfunctions.net/videosApi/getAll/1001`,
  requestHorror: `https://us-central1-${prefix}-thebeatsbase.cloudfunctions.net/videosApi/getAll/1001`,
  requestUpcoming: `https://us-central1-${prefix}-thebeatsbase.cloudfunctions.net/videosApi/getAll/1001`,
  requestUpdateViewer: `https://us-central1-${prefix}-thebeatsbase.cloudfunctions.net/viewersApi/update/`,
};

export default requests;
