import React from "react";
import ContentPurchase from "../components/ContentPurchase";
import poupelleImage from "../assets/poupelle.jpg";
import { useLocation } from "react-router-dom";

const ContentDetail = () => {
  const { state } = useLocation();
  return (
    <>
      <div className="w-full text-white">
        <img
          className="w-full h-[400px] object-cover"
          src={poupelleImage}
          alt="/"
        />
        <div className="bg-black/60 fixed top-0 left-0 w-full h-[550px]"></div>
        <div className="absolute top-[20%] p-4 md:p-8">
          <h1 className="text-3xl md:text-5xl font-bold">
            ミュージカル「えんとつ町のプペル」
          </h1>
          <br />
          <h4 className="text-1xl md:text-1xl">
            Original Author: Akihiro Nishino
          </h4>
          <h4 className="text-1xl md:text-1xl">Produced by Joe</h4>
          <h4 className="text-1xl md:text-1xl">Presented by </h4>
        </div>
      </div>
      <ContentPurchase />
    </>
  );
};

export default ContentDetail;
