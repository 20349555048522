import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";

const Success = () => {
  const [cardNumber, setCardNumber] = useState("");
  const [error, setError] = useState("");
  const { user, logIn } = UserAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      // await logIn(email, password);
      navigate("/mypage");
    } catch (error) {
      console.log(error);
      setError(error.message);
    }
  };

  return (
    <div className="w-full h-screen">
      <img
        className="hidden sm:block absolute w-full h-full object-cover"
        src="https://source.unsplash.com/23LET4Hxj_U"
        alt="/"
      />
      <div className="bg-black/60 fixed top-0 left-0 w-full h-screen"></div>
      <div className="fixed w-full px-4 py-24 z-50">
        <div className="max-w-[550px] h-[600px] mx-auto bg-black/75 text-white">
          <div className="max-w-[420px] mx-auto py-16">
            <h1 className="text-3xl font-bold">お支払いの完了</h1>
            <p>お支払いいただき、誠にありがとうございました。</p>
            <button className="bg-blue-600 py-3 my-6 rounded font-bold" onClick={handleSubmit} >
              マイページを戻る
	    </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Success;
