import { Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import { AuthContextProvider } from "./context/AuthContext";
import TempHome from "./pages/TempHome";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import PasswordRemind from "./pages/PasswordRemind";
import MyPage from "./pages/MyPage";
import Payment from "./pages/Payment";
import Success from "./pages/Success";
import Failure from "./pages/Failure";
import ProtectedRoute from "./components/ProtectedRoute";
import Container from "./components/Container";
import ContentDetail from "./pages/ContentDetail";
import Footer from "./components/Footer";
import Terms from "./components/Terms";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Law from "./components/Law";

function App() {
  return (
    <>
      <AuthContextProvider>
        <Navbar />
        <Routes>
          <Route path="/" element={<Container />} />
          <Route path="content-detail" element={<ContentDetail />}>
            {/* <Route path=":content" element={<ContentDetail />} /> */}
          </Route>
          <Route path="/tempHome" element={<TempHome />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/password-remind" element={<PasswordRemind />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/law" element={<Law />} />
          <Route
            path="/mypage"
            element={
              <ProtectedRoute>
                <MyPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/payment"
            element={
              <ProtectedRoute>
                <Payment />
              </ProtectedRoute>
            }
          />
	  <Route path="/success" element={<ProtectedRoute><Success /></ProtectedRoute>} />
	  <Route path="/failure" element={<ProtectedRoute><Failure /></ProtectedRoute>} />
        </Routes>
        <Footer />
      </AuthContextProvider>
    </>
  );
}

export default App;
