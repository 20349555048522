import React, { useState } from 'react';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import { UserAuth } from '../context/AuthContext';
import { db } from '../firebase';
import { arrayUnion, doc, updateDoc } from 'firebase/firestore';
import { useLocation } from 'react-router-dom';
import ReactPlayer from 'react-player';

const Movie = ({ item }) => {
  const [like, setLike] = useState(false);
  const [saved, setSaved] = useState(false);
  const { user } = UserAuth();

  const movieID = doc(db, 'users', `${user?.email}`);

  const location = useLocation();
  const videoid = item.id;
  const title = item.title;
  //const duration = location.state.duration;
  //const videofile = location.state.videofile;
  const imgUrl = item.img_url;

  const saveShow = async () => {
    if (user?.email) {
      setLike(!like);
      setSaved(true);
      await updateDoc(movieID, {
        savedShows: arrayUnion({
          id: item.id,
          title: item.title,
          img: item.backdrop_path,
        }),
      });
    } else {
      alert('Please log in to save a movie');
    }
  };

  const putVideoPlayLog = () => {
    console.log("video play started....");
  };

  return (
    <React.Fragment>
      <h3 style={{color: 'white'}}>{item.title}</h3>
      <div className="player-wrapper">
        <ReactPlayer
          url={item.url}
          className="react-player"
          controls={true}
          playing={false}
	  width="100%"
	  height="100%"
          onStart={() => putVideoPlayLog()}
	  config={{ file: {
            attributes: {
              controlsList: 'nodownload'
	    }
          }}}

        />
      </div>
    </React.Fragment>
  );
};

export default Movie;
