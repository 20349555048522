import React from "react";
import styled from "styled-components";
import Filter from "./Filter";
import Search from "./Search";
import Header from "./Header";
import ContentList from "./ContentList";
// import ResponsiveDrawer from "./ResponsiveDrawer";

const Div = styled.div`
  margin-left: 70px;
`;

const Container = () => {
  return (
    <>
      <Div>
        <Search />
        <Filter />
        <Header />
        <ContentList />
      </Div>
    </>
  );
};

export default Container;
