import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
// import MovieContext from "../MovieContext";
// import genres from "../dummy-data/genres";
import { BiArrowFromRight } from "react-icons/bi";
import { MdMoreHoriz } from "react-icons/md";

const Div = styled.div`
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
`;

const Button = styled.button`
  background-color: hsl(223, 36%, 16%);
  color: hsl(223, 36%, 94%);
  font-family: "Inter", sans-serif;
  border-radius: 15px;
  border: none;
  padding: 10px 25px;
  cursor: pointer;
  font-weight: 500;
  &:hover {
    background-color: hsl(223, 36%, 24%);
  }
  &.active {
    background-color: hsl(224, 32%, 94%);
    color: hsl(224, 32%, 9%);
  }
  &.more {
    color: hsl(221, 23%, 63%);
    background-color: transparent;
    display: flex;
    align-items: center;
    font-size: 18px;
    padding: 0 10px;
  }
  &.more&:hover {
    background-color: hsl(223, 36%, 24%);
  }
`;

const Filter = () => {
  //   const { setActiveGenre, activeGenre, setFiltered, movies, header } =
  //     useContext(MovieContext);

  const [moreGenres, setMoreGenres] = useState(false);

  //   useEffect(() => {
  //     if (activeGenre === 0) {
  //       setFiltered(movies);
  //     } else {
  //       const filtered = movies.filter((movie) =>
  //         movie.genre_ids.includes(activeGenre)
  //       );
  //       setFiltered(filtered);
  //     }
  //   }, [activeGenre, header]);

  return (
    <Div>
      {/* <button
        className={activeGenre === 0 ? "active" : null}
        onClick={() => setActiveGenre(0)}
      >
        All
      </button>
      <button
        className={activeGenre === 28 ? "active" : null}
        onClick={() => setActiveGenre(28)}
      >
        Action
      </button>
      <button
        className={activeGenre === 12 ? "active" : null}
        onClick={() => setActiveGenre(12)}
      >
        Adventure
      </button>
      {moreGenres &&
        genres.map((genre) => (
          <button
            key={genre.id}
            className={activeGenre === genre.id ? "active" : null}
            onClick={() => setActiveGenre(genre.id)}
          >
            {genre.name}
          </button>
        ))} */}
      <Button className="more" onClick={() => setMoreGenres(!moreGenres)}>
        {moreGenres ? <BiArrowFromRight /> : <MdMoreHoriz />}
      </Button>
    </Div>
  );
};

export default Filter;