import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { db } from "../firebase";
import { updateDoc, doc, onSnapshot } from "firebase/firestore";
import Spinner from "./Spinner";

const ContentPurchase = () => {
  const [error, setError] = useState("");
  const [isLoading, SetIsLoading] = useState(false);
  const navigate = useNavigate();
  const [movies, setMovies] = useState([]);
  const { user } = UserAuth();
  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      // await signUp(email, password);
      // navigate("/");
      const res = await axios.post(
        "https://us-central1-dev-thebeatsbase.cloudfunctions.net/stripesApi/create-checkout-session",
        {
          priceId: "price_1MKhWODjmhkvXJVSNNb8VhUF",
          quantity: 1,
          uid: user.uid,
          email: user.email,
          name: user.displayName,
          currency: "JPY",
          productId: "prod_N4rECoqSZFSq1D",
        },
        { headers }
      );
      const jsonifyObj = JSON.parse(res.data.data);
      console.log("result....", res.data.data, jsonifyObj.result.url);
      window.location.replace(jsonifyObj.result.url);
    } catch (error) {
      console.log(error);
    }
  };
  const handleGoToSignUp = () => {
    navigate("/signup");
  };
  const handleClick = () => {
    SetIsLoading(true);
  };

  useEffect(() => {
    onSnapshot(doc(db, "viewers", `${user?.email}`), (doc) => {
      setMovies(doc.data()?.savedShows);
    });
  }, [user?.email]);

  const movieRef = doc(db, "viewers", `${user?.email}`);
  const deleteShow = async (passedID) => {
    try {
      const result = movies.filter((item) => item.id !== passedID);
      await updateDoc(movieRef, {
        savedShows: result,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <h2 className="te font-bold md:text-xl p-4">あらすじ</h2>
      <div className="relative flex items-center group">
        <div
          id={"slider"}
          className="w-full h-full overflow-x-scroll whitespace-nowrap scroll-smooth scrollbar-hide relative"
        >
          <h2 className="text-white font-bold md:text-sm p-7">
            厚い煙に覆われた“えんとつ町”。煙の向こうに“星”があるなんて誰も想像すらしなかった。
            <br />
            一年前、この町でただ一人、紙芝居に託して“星”を語っていたブルーノが突然消えてしまい、人々は海の怪物に食べられてしまったと噂した。
            <br />
            ブルーノの息子・ルビッチは、学校を辞めてえんとつ掃除屋として家計を助ける。
            <br />
            しかしその後も父の教えを守り“星”を信じ続けていたルビッチは町のみんなに嘘つきと後ろ指をさされ、ひとりぼっちになってしまう。
            <br />
            そしてハロウィンの夜、彼の前に奇跡が起きた。ゴミから生まれたゴミ人間・プペルが現れ、のけもの同士、二人は友達となる。
            <br />
            そんなある日、巨大なゴミの怪物が海から浮かび上がる。それは父の紙芝居に出てきた、閉ざされたこの世界には存在しないはずの“船”だった。
            <br />
            父の話に確信を得たルビッチは、プペルと「星を見つけに行こう」と決意する。しかしこの町の治安を守る異端審問官が二人の計画を阻止するために立ちはだかる。
            <br />
            それでも父を信じて、互いを信じあって飛び出した二人が、大冒険の先に見た、えんとつ町に隠された驚きの秘密とは？
          </h2>
          {!user ? (
            <form
              onSubmit={handleGoToSignUp}
              className="w-full flex flex-col py-4"
            >
              <button className="bg-blue-600 py-3 my-6 rounded font-bold">
                購入には会員登録もしくはログインが必要です
              </button>
            </form>
          ) : (
            <></>
          )}
          {/* TODO: 本番環境の動画販売は準備できるまでpending */}
          {user &&
          process.env.REACT_APP_FIREBASE_PROJECT_ID === "prod-thebeatsbase" ? (
            <form className="w-full flex flex-col py-4">
              <button className="bg-blue-600 py-3 my-6 rounded font-bold">
                販売開始まであと少し
              </button>
              <p className="py-8">
                <span className="text-gray-600">
                  販売開始までお待ちください
                </span>{" "}
              </p>
            </form>
          ) : (
            <></>
          )}
          {/* TODO: dev環境の動画販売は行う */}
          {user &&
          process.env.REACT_APP_FIREBASE_PROJECT_ID === "dev-thebeatsbase" ? (
            <form onSubmit={handleSubmit} className="w-full flex flex-col py-4">
              {/* <Spinner isLoading={isLoading}>
                </Spinner> */}
              <button
                className="bg-blue-600 py-3 my-6 rounded font-bold"
                onClick={handleClick}
              >
                動画を購入する
              </button>
              <p className="py-8">
                <span className="text-gray-600">購入画面へ進みます。</span>{" "}
              </p>
            </form>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default ContentPurchase;
