import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";

const Payment = () => {
  const [cardNumber, setCardNumber] = useState("");
  const [error, setError] = useState("");
  const { user, logIn } = UserAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      // await logIn(email, password);
      navigate("/mypage");
    } catch (error) {
      console.log(error);
      setError(error.message);
    }
  };

  return (
    <div className="w-full h-screen">
      <img
        className="hidden sm:block absolute w-full h-full object-cover"
        src="https://source.unsplash.com/23LET4Hxj_U"
        alt="/"
      />
      <div className="bg-black/60 fixed top-0 left-0 w-full h-screen"></div>
      <div className="fixed w-full px-4 py-24 z-50">
        <div className="max-w-[550px] h-[600px] mx-auto bg-black/75 text-white">
          <div className="max-w-[420px] mx-auto py-16">
            <h1 className="text-3xl font-bold">お支払い情報更新</h1>
            {error ? <p className="p-3 bg-blue-400 my-2">{error}</p> : null}
            <form onSubmit={handleSubmit} className="w-full flex flex-col py-4">
              <input
                onChange={(e) => setCardNumber(e.target.value)}
                className="p-3 my-2 bg-gray-700 rouded"
                type="email"
                placeholder="クレジットカード番号"
              />
              <button className="bg-blue-600 py-3 my-6 rounded font-bold">
                クレジットカード情報を登録する
              </button>
              {/* <div className="flex justify-between items-center text-sm text-gray-600">
                <p>
                  <input className="mr-2" type="checkbox" />
                  Remember me
                </p>
                <p>Need Help?</p>
              </div> */}
              {/* <p className="py-8">
                <span className="text-gray-600">New to THE BeatsBase?</span>{" "}
                <Link to="/signup">Sign Up</Link>
              </p> */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
