import React from "react";
import styled from "styled-components";
import { useContext } from "react";
// import MovieContext from "../MovieContext";

const Div = styled.div`
  margin-left: 70px;
`;

const Header = () => {
  // const { header } = useContext(MovieContext);

  //   return <h1 className="section-title">{header}</h1>;
  return (
    <>
      <Div>
        <h1 className="section-title">{"THE BeatsBase"}</h1>
      </Div>
    </>
  );
};

export default Header;
