import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";

const PasswordRemind = () => {
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const { user, passwordRemind } = UserAuth();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
        try {
            await passwordRemind(email);
            navigate("/login");
        } catch (error) {
            console.log(error);
            setError("正しいEmailを入力してください");
        }
    };

    return (
        <div className="w-full h-screen">
            <img
                className="hidden sm:block absolute w-full h-full object-cover"
                src="https://source.unsplash.com/23LET4Hxj_U"
                alt="/"
            />
            <div className="bg-black/60 fixed top-0 left-0 w-full h-screen"></div>
            <div className="fixed w-full px-4 py-24 z-50">
                <div className="max-w-[450px] h-[600px] mx-auto bg-black/75 text-white">
                    <div className="max-w-[320px] mx-auto py-16">
                        <h1 className="text-3xl font-bold">パスワード再設定</h1>
                        {error ? <p className="p-3 bg-blue-400 my-2">{error}</p> : null}
                        <form onSubmit={handleSubmit} className="w-full flex flex-col py-4">
                            <input
                                onChange={(e) => setEmail(e.target.value)}
                                className="p-3 my-2 bg-gray-700 rouded"
                                type="email"
                                placeholder="Email"
                                autoComplete="email"
                            />
                            <button className="bg-blue-600 py-3 my-6 rounded font-bold">
                                パスワードを再設定する
                            </button>
                            <div className="flex justify-between items-center text-sm text-gray-600">
                                <span className="text-gray-600">
                                    THE BeatsBaseアカウントはありますか?
                                </span>{" "}
                                <Link to="/login">ログイン</Link>
                            </div>
                            <p className="py-8">
                                <span className="text-gray-600">
                                    THE BeatsBaseは初めてのご利用ですか?
                                </span>{" "}
                                <Link to="/signup">会員登録</Link>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PasswordRemind;
