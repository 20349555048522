import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Content from "./Content";
import { motion } from "framer-motion";
import { useContent } from "../hooks/useContent";

const Div = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
`;

const ContentList = () => {
  // const contents = useContent();
  // const videos = useContent();

  // const videos = await useContent().fetchVideos();
  // console.log('videos: ',videos);

  const content = useContent();
  // console.log(content.videos);

  const videos = useContent().videos;
  // const videosArray = Object.entries(videos);

  return (
    <>
      <Div>
        {/* <motion.div layout>
        <Div>
          修正中
          {Array.isArray(videos) && videos.map((content: any) => {
            console.log(content);
            return <Content key={content.id} content={content} />;
          })}
        </Div>
      </motion.div> */}
        {Array.isArray(content.contents) && content.contents.map((content) => {
          return (
            <>
              <motion.div layout>
                <Div>
                  <Content key={content.id} content={content} />
                </Div>
              </motion.div>
            </>
          );
        })}
        {Array.isArray(content.contents) && content.contents.length === 0 && (
          <p className="info">検索結果がありません。</p>
        )}
      </Div>
    </>
  );
};

export default ContentList;