import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useContext } from "react";
// import MovieContext from "../MovieContext";

const Div = styled.div`
  margin: 70px;
`;

const Footer = () => {
  // const { header } = useContext(MovieContext);

  //   return <h1 className="section-title">{header}</h1>;
  return (
    <>
      <Div>
        <h1 className="text-white text-1xl font-bold cursor-pointer">
          <Link to="/terms">
            利用規約
          </Link>
        </h1>
        <h1 className="text-white text-1xl font-bold cursor-pointer">
          <Link to="/privacy-policy">
            プライバシーポリシー
          </Link>
        </h1>
        <h1 className="text-white text-1xl font-bold cursor-pointer">
          <Link to="/law">
            特定商取引法に基づく表記
          </Link>
        </h1>
        <h1 className="text-white text-1xl font-bold cursor-pointer">
          <a target="_blank" href="https://langdemy.com/" rel="noreferrer">
            運営会社
          </a>
        </h1>
      </Div>
    </>
  );
};

export default Footer;
